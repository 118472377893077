<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="录入订单数量" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="fullname">
					<el-select v-model="searchForm.fullName" placeholder="请选择录单人员" @change="changeShop" filterable
						clearable>
						<el-option v-for="item in options" :key="item.id" :label="item.fullName"
							:value="item.fullName" />
					</el-select>
				</el-form-item>
				<el-form-item prop="receiverMobile">
					<SearchForm>
						<div class="popperClass">
							<el-date-picker v-model.trim="daterange" popper-class="popperClass"
								 type="daterange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
						</div>
					</SearchForm>
				</el-form-item>


				<el-form-item>
					<el-button type="primary" @click="searchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer >
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }" >
				<el-table-column prop="id" label="id" min-width="130" />
				<el-table-column prop="fullName" label="姓名" min-width="130" />
				<el-table-column prop="count" label="数量" min-width="110" />
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		

	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		// noBeReviewedTrade,
		// findAllUser,
		// updateUserStatus,
		// getDictoryInfoByCode,
		// batchUpdateUserStatus,
		anchorIn,
		anchorInData
	} from '@api/gardenManagement/api_gardenManagement'
	export default {
		data() {
			return {
				tableData: [],
				tabList: [],
				options: [],
				total: 0,
				searchForm: {
					id:'',
					fullName: "",
					page: 1,
					size: 20,
					startTime: '',
					endTime: '',
				},
				daterange:''
			}
		},
		created() {
		
			this.anchorIn()
			if(this.searchForm.id!==''){
				this.fetchData()
			}
			
		
			
		},

		methods: {

			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			anchorIn() {
				anchorIn({}).then(res => {	
					this.options = res
				})
			},
			changeShop() {
				// console.log(this.searchForm.fullName)
			
				this.options.map(item => {
                 if(this.searchForm.fullName==item.fullName){

					this.searchForm.id=item.id
				 }
          })
				
			},
			searchData(){
				this.tableData=[]
				if(this.searchForm.fullName==''){
					this.$message.warning('请选择录单人员')
					return
				}
				if(this.searchForm.beginTime==''){
					this.$message.warning('请选择开始时间')
					return
				}
				if(this.searchForm.endTime==''){
					this.$message.warning('请选择结束时间')
					return
				}
				this.fetchData()

			},
			fetchData() {
				anchorInData(this.searchForm).then(res => {
					this.tableData.push(res)
					// console.log(this.tableData)
					
				})
			},
			formatDate(date, fmt) {
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				let o = {
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
					}
				}
				return fmt
			},
			padLeftZero(str) {
				return ('00' + str).substr(str.length)
			},
			handleSearch(val) {
				let date = val[0];
				let date1 = val[1];
				this.searchForm.startTime = this.formatDate(date, 'yyyy-MM-dd');
				this.searchForm.endTime = this.formatDate(date1, 'yyyy-MM-dd');

			},
	

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.customWidth {
		width: 50%;
		// text-align: center;
	}

	.popperClass {
		.el-date-editor .el-range-separator {
			width: 20%;
		}
	}

	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.reviewed {
		color: #38b9be;
	}
</style>
